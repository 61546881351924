import likeQLogoLarge from './likeQ-logo-text@2x.png';
import likeQLogoRegular from './likeQ-logo-name@2x.png';
import likeQLogoMono from './likeQ-logo-mono@2x.png';
import qvcLogo from './qvc-logo-mono@2x.png';
import phone from './iPhone.jpg';
import appStore from './app-store@2x.png';
import googlePlay from './google-play@2x.png'
import './fonts.css';
import './App.css';
import Modali, { useModali } from 'modali';

import dyson from './dyson@2x.png';
import smeg from './smeg@2x.png';
import illamasqua from './illamasqua@2x.png';
import philosophy from './philosophy@2x.png';
import isawitfirst from './isawitfirst@2x.png';
import lee from './lee@2x.png';
import opi from './opi@2x.png';
import itcosmetics from './itcosmetics@2x.png';

import instagram from './instagram@2x.png';
import facebook from './facebook@2x.png';

import promo1 from './promo1.jpg';
import promo2 from './promo2.jpg';
import promo3 from './promo3.jpg';

function App() {
  const [aboutModal, toggleAboutModal] = useModali({animated: true, large: true, title: "About"});
  const [privacyModal, togglePrivacyModal] = useModali({animated: true, large: true, title: "Privacy Policy"});
  const [termsModal, toggleTermsModal] = useModali({animated: true, large: true, title: "Terms and Conditions"});
  const [cookieModal, toggleCookieModal] = useModali({animated: true, large: true, title: "Cookie Policy"});
  const [faqModal, toggleFAQModal] = useModali({animated: true, large: true, title: "F.A.Q."});
  const [competitionModal, toggleCompetitionModal] = useModali({animated: true, large: true, title: "Regolamento Concorsi"});
  const [contactModal, toggleContactModal] = useModali({animated: true, large: true, title: "Contatti"});

  function AppStores() {
    return (
      <>
        <div className="appStores">
          <a href="https://apps.apple.com/it/app/likeq-live-shopping/id1593164839" target="_blank" rel="noreferrer"><img src={appStore} className="appStore" alt="Apple Store logo" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.ooooo.italy" target="_blank" rel="noreferrer"><img src={googlePlay} className="googlePlay" alt="Google Play logo" /></a>
        </div>
      </>
    )
  }

  function PrivacyLinks() {
    return (
      <>
        <div className="privacyLinks">
          <button onClick={toggleAboutModal} className="desktopHidden">About</button>
          <button onClick={togglePrivacyModal}>Privacy Policy</button>
          <button onClick={toggleTermsModal}>Terms and Conditions</button>
          <button onClick={toggleCookieModal}>Cookie Policy</button>
          <button onClick={toggleFAQModal}>F.A.Q.</button>
          <button onClick={toggleCompetitionModal}>Regolamento Concorsi</button>
          <button onClick={toggleContactModal}>Contatti</button>
        </div>
      </>
    )
  }

  return (
    <div className="app">
      <header>
        <div className="innerContent">
          <button onClick={toggleAboutModal}>About</button>
          <button onClick={toggleContactModal}>Contatti</button>
        </div>
      </header>
      <div className="container">
        <div className="innerContent">
          <div className="right">
            <img src={likeQLogoLarge} className="likeQLogoLarge" alt="likeQ logo" />
            <img src={likeQLogoRegular} className="likeQLogoRegular" alt="likeQ logo" />
            <h1>Shop live, {"\n"}as you like</h1>
            <div className="subHeading">
              <p>Con likeQ hai tantissimi esperti di shopping con cui interagire in tempo reale e la possibilità di trasformare ogni loro consiglio nel tuo shopping personale con un semplice tap.</p>
            </div>
            <AppStores />
          </div>
          <div className="left">
            <div>
              <img src={phone} className="phone" alt="A phone with likeQ app running" />
            </div>
          </div>
        </div>
      </div>
      <div className="container livestreamShopping">
        <div className="innerContent">
          <h2>Oggi il divertimento è il livestream shopping!</h2>
          <p>Con likeQ puoi scoprire ogni settimana grazie ai nostri esperti di shopping nuovi prodotti, marche da amare, brand famosi e firme esclusive.</p>
        </div>
      </div>
      <div className="container">
        <div className="innerContent">
          <div className="brandLogos">
            <div className="logoWrapper">
              <img src={dyson} className="logo" alt="Dyson logo" />
            </div>
            <div className="logoWrapper">
              <img src={smeg} className="logo" alt="Smeg logo" />
            </div>
            <div className="logoWrapper">
              <img src={illamasqua} className="logo" alt="ILLAMASQUA logo" />
            </div>
            <div className="logoWrapper">
              <img src={philosophy} className="logo" alt="Philosophy logo" />
            </div>
            <div className="logoWrapper">
              <img src={isawitfirst} className="logo" alt="I Saw It First logo" />
            </div>
            <div className="logoWrapper">
              <img src={lee} className="logo" alt="Lee logo" />
            </div>  
            <div className="logoWrapper">
              <img src={opi} className="logo" alt="OPI logo" />
            </div>
            <div className="logoWrapper">
              <img src={itcosmetics} className="logo" alt="IT Cosmetics logo" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="innerContent">
          <div className="spotlights">
            <div className="promo">
              <h3>Watch</h3>
              <p>Guarda gli show e i contenuti esclusivi in livestreaming, e valuta live tutti i prodotti che vorresti acquistare.</p>
              <img src={promo1} className="screenshot" alt="App screenshot" />
            </div>
            <div className="promo">
              <h3>Connect</h3>
              <p>Interagisci con i video dei nostri esperti di shopping in livestreaming con un semplice tou ch.</p>
              <img src={promo2} className="screenshot" alt="App screenshot" />
            </div>
            <div className="promo">
              <h3>Shopping</h3>
              <p>Scopri ogni giorno nuovi prodotti, marche da amare, brand famosi e firme esclusive.</p>
              <img src={promo3} className="screenshot" alt="App screenshot" />
            </div>
          </div>
        </div>
      </div>
      <div className="mobileContainer">
        <div className="innerContent">
          <h2>Cos’è likeQ?</h2>
          <p>likeQ è la nuova app di intrattenimento e shopping fusi insieme. Con likeQ hai tantissimi esperti di shopping con cui interagire in tempo reale e la possibilità di trasformare ogni loro consiglio nel tuo shopping personale con un semplice touch.</p>
          <a href="https://www.qvc.it/contenuti/app-likeq.html">Scopri di più su QVC.it</a>
        </div>
      </div>
      <div className="container desktopFooter">
        <div className="innerContent">
          <AppStores />
          <div className="socialMedia">
            <a href="https://www.instagram.com/likeq_italy/" target="_blank" rel="noreferrer">
              <img src={instagram} className="socialMediaLogo" alt="Instagram logo" />
              <span>Seguici su Instagram</span>
            </a>
            <a href="https://www.facebook.com/likeQItaly" target="_blank" rel="noreferrer">
              <img src={facebook} className="socialMediaLogo" alt="Facebook logo" />
              <span>Seguici su Facebook</span>
            </a>
          </div>
        </div>
      </div>
      <div className="mobileContainer">
        <div className="innerContent">
          <PrivacyLinks />
        </div>
      </div>
      <footer>
        <div className="innerContent">
          <PrivacyLinks />
          <div className="footerLeft">
            <div className="qvcLogoContainer">
              <img src={likeQLogoMono} className="likeQLogoMono" alt="LikeQ logo" />
              <span>powered by</span>
              <img src={qvcLogo} className="qvcLogo" alt="QVC logo" />
            </div>
            <div className="copyright">
              <span>Copyright© QVC, Inc. 2022</span>
            </div>
          </div>
        </div>
      </footer>
      <Modali.Modal {...aboutModal}>
        <iframe src="/it/about/index.html" title="About"></iframe>
      </Modali.Modal>
      <Modali.Modal {...privacyModal}>
        <iframe src="/it/privacy-policy/index.html" title="Privacy Policy"></iframe>
      </Modali.Modal>
      <Modali.Modal {...termsModal}>
        <iframe src="/it/terms-and-conditions/index.html" title="Terms and Conditions"></iframe>
      </Modali.Modal>
      <Modali.Modal {...cookieModal}>
        <iframe src="/it/cookie-policy/index.html" title="Cookie Policy"></iframe>
      </Modali.Modal>
      <Modali.Modal {...faqModal}>
        <iframe src="/it/faq/index.html" title="F.A.Q."></iframe>
      </Modali.Modal>
      <Modali.Modal {...competitionModal}>
        <iframe src="/it/concorso/regolamento-concorsi.html" title="Regolamento Concorsi"></iframe>
      </Modali.Modal>      
      <Modali.Modal {...contactModal}>
        <iframe src="/it/contact/index.html" title="Contatti"></iframe>
      </Modali.Modal>
    </div>
  );
}

export default App;
